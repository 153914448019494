export const PRIORITIES = [
    { value: 0, label: "全て"},
    { value: 1, label: "最優先", class:"orange--text"},
    { value: 2, label: "高", class:"red--text"},
    { value: 3, label: "中", class:"amber--text" },
    { value: 4, label: "低", class:"blue--text" },
]

export const SELECTPRI = [
    { value: 1, label: "最優先", class:"orange--text"},
    { value: 2, label: "高", class:"red--text"},
    { value: 3, label: "中", class:"amber--text" },
    { value: 4, label: "低", class:"blue--text" },
    { value: 5, label: "未選択"},
]

export const STATUS = [
    { value:0, label:"全て" },
    { value:1, label:"未対応", class:"red--text"},
    { value:2, label:"処理中", class:"orange--text"},
    // { value:3, label:"処理済み", class:"light-green--text"},
    { value:4, label:"確認中", class:"green--text"},
    { value:5, label:"完了", class:"blue--text"},
]

export const SELECTSTA = [
    { value:1, label:"未対応", class:"red--text"},
    { value:2, label:"処理中", class:"orange--text"},
    // { value:3, label:"処理済み", class:"light-green--text"},
    { value:4, label:"確認中", class:"green--text"},
    { value:5, label:"完了", class:"blue--text"},
    { value:6, label:"未選択" },
]

export const TEAM = [
    { value:1, label:"デザイン"},
    { value:2, label:"コンサル"},
    { value:3, label:"インスタ"},
]

export const ATTRIBUTE = [
    { value:1, label:"社員"},
    { value:2, label:"インターン生"},
]