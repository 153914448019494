<template>
  <div :class="{ 'is-sp': $vuetify.breakpoint.xs }">
    <v-card>
      <v-card-title>編集（{{ user.name }}）</v-card-title>
      <Loading v-if="isLoading" />
      <p v-else-if="tag === null">通信エラー</p>
      <v-card-text v-else>
        <section class="user_info">
          <v-card-subtitle>ユーザー情報</v-card-subtitle>
          <section>
            <ul>
              <li class="mb-5 mr-3">
                <label v-if="$store.state.loginInfo.id == user.id">
                  <v-avatar size="40px">
                    <v-img
                      v-if="!user.img"
                      :src="require('@/assets/images/user.png')"
                    ></v-img>
                    <v-img v-else-if="this.picture" :src="this.picture"></v-img>
                    <v-img
                      v-else
                      :src="VUE_APP_BACK_URL + '/storage/images/' + user.img"
                    ></v-img>
                  </v-avatar>
                  <form>
                    <input class="file_button" type="file" @change="upload" />
                  </form>
                </label>
                <v-avatar v-else size="40px">
                  <v-img
                    v-if="!user.img"
                    :src="require('@/assets/images/user.png')"
                  ></v-img>
                  <v-img
                    v-else
                    :src="VUE_APP_BACK_URL + '/storage/images/' + user.img"
                  ></v-img>
                </v-avatar>
              </li>
              <li>
                <v-text-field
                  label="名前"
                  v-model="user.name"
                  prepend-inner-icon="mdi-account"
                  dense
                  outlined
                  color="primary"
                  class="mr-2"
                ></v-text-field>
                <p v-if="newUserNameError" class="error-text">
                  {{ newUserNameError }}
                </p>
              </li>
              <li>
                <v-text-field
                  label="メールアドレス"
                  v-model="user.email"
                  prepend-inner-icon="mdi-email"
                  dense
                  outlined
                  color="primary"
                  class="mr-2"
                ></v-text-field>
                <p v-if="newUserEmailError" class="error-text">
                  {{ newUserEmailError }}
                </p>
              </li>
              <li style="width:250px;">
                <v-select v-model="user.team_id" :items="TEAM" label="チーム" :item-value="id" item-text="label" prepend-inner-icon="mdi-account-supervisor-circle" outlined dense color="primary"></v-select>
                <p v-if="newUserTeamIdError" class="error-text">
                  {{ newUserIdError }}
                </p>
              </li>
              <li style="width:250px;">
                <v-select v-model="user.attribute" :items="ATTRIBUTE" label="属性" :item-value="id" item-text="label" prepend-inner-icon="mdi-badge-account-horizontal" outlined dense color="primary"></v-select>
                <p v-if="newUserAttributeError" class="error-text">
                  {{ newUserAttributeError }}
                </p>
              </li>
            </ul>
          </section>
          <section>
            <ul>

              <li>
                <v-text-field
                  label="現在のパスワード"
                  v-model="userPass"
                  prepend-inner-icon="mdi-lock"
                  dense
                  outlined
                  color="primary"
                  class="mr-2 ml-13"
                ></v-text-field>
                <p v-if="userPassError" class="error-text ml-13">{{ userPassError }}</p>
              </li>
              <li>
                <v-text-field
                  label="新しいパスワード"
                  v-model="newUserPass"
                  prepend-inner-icon="mdi-lock"
                  dense
                  outlined
                  color="primary"
                  class="mr-2"
                ></v-text-field>
                <p v-if="newUserPassError" class="error-text">
                  {{ newUserPassError }}
                </p>
              </li>
            </ul>
          </section>
        </section>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="isLoading" @click="deleltedUser()" color="error">
          <span>削除</span>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isLoading" @click="changeUser()" color="primary">
          <span>登録</span>
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- <ImagePicker
      :isShowImagePicker="isShowImagePicker"
      :onSelectedImg="onSelectedImg"
      :onCloseMyself="onCloseMyself"
    /> -->

    <!-- コメントアウトを外すとイメージピッカーが出現します -->

    <!-- <v-dialog :value="true" scrollable>
      <v-card class="image_picker">
        <v-card-title>アイコン画像を選択してください</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3" style="min-height:30vh;">
          <ul>
            <li v-for="n in 99" :key="n">
              <v-card v-ripple>
                <v-img :src="`https://picsum.photos/500/300?image=${n}`"></v-img>
              </v-card>
            </li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>
<script>
// import ImagePicker from "@/components/ImagePicker.vue";
import { TEAM } from "@/plugins/const";
import { ATTRIBUTE } from "@/plugins/const";
import Loading from "@/components/Loading.vue";
import { myAxios } from "@/plugins/axios";
export default {
  components: {
    Loading,
    // ImagePicker,
  },
  data() {
    return {
      // isShowImagePicker: false,
      TEAM: TEAM,
      ATTRIBUTE: ATTRIBUTE,
      picture: "",
      user: {},
      isLoading: false,
      newUserName: "",
      newUserNameError: "",
      newUserEmail: "",
      newUserEmailError: "",
      userPass: "",
      userPassError: "",
      newUserPass: "",
      newUserPassError: "",
      newUserAttribute: "",
      newUserAttributeError: "",
      selectedFile: "",
      result: false,
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
    };
  },

  methods: {
    onSelectedImg(path) {
      this.user.img = path;
      this.isShowImagePicker = false;
    },
    onCloseMyself() {
      this.isShowImagePicker = false;
    },
    async getUser() {
      this.isLoading = true;
      const requestConfig = {
        url: "/user",
        method: "GET",
        params: {
          id: this.$route.query.userId,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.user = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async deleltedUser() {
      if (!confirm("本当に削除しますか？")) return;
      const requestConfig = {
        url: "/user",
        method: "DELETE",
        data: {
          id: this.$route.query.userId,
        },
      };
      myAxios(requestConfig).then(() => {
        this.$router.push("/main/user");
      });
    },
    async changeUser() {
      let isError = false;
      this.newUserNameError = "";
      this.newUserEmailError = "";
      this.newUserPassError = "";
      this.userPassError = "";
      this.newUserAttributeError = "";
      this.newUserTeamIdError = "";
      if (this.user.name === "") {
        isError = true;
        this.newUserNameError = "名前を入力してください";
      }
      if (!/.+@.+\..+/.test(this.user.email)) {
        isError = true;
        this.newUserEmailError = "正しいメールアドレスの形式で入力してください";
      }
      if (0 < this.newUserPass.length && this.newUserPass.length < 8) {
        isError = true;
        this.newUserPassError = "パスワードは8文字以上で入力してください";
      }
      if (this.userPass === "") {
        isError = true;
        this.userPassError = "現在のパスワードを入力してください";
      }
      if (this.user.attribute === "") {
        isError = true;
        this.newUserAttributeError = "属性を選択してください";
      }
      if (this.user.team_id === "") {
        isError = true;
        this.newUserAttributeError = "チームを選択してください";
      }
      if (isError) return;
      const requestConfig = {
        url: "/user",
        method: "PUT",
        data: {
          id: this.$route.query.userId,
          name: this.user.name,
          email: this.user.email,
          pass: this.userPass,
          newPass: this.newUserPass,
          img: this.user.img,
          attribute: this.user.attribute,
          team_id: this.user.team_id,
        },
      };
      this.isLoading = true;
      myAxios(requestConfig)
        .then(() => {
          this.$router.push("/main/user");
        })
        .catch((err) => {
          this.userPassError = err.response.data.errorMessage;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async upload(event) {
      this.selectedFile = event.target.files[0];
      let file = event.target.files[0];

      if (this.checkFile(this.selectedFile)) {
        this.picture = await this.getBase64(file);

        const formData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("fileName", this.selectedFile.name);
  
        const requestConfig = {
          url: "/user_image",
          method: "POST",
          data: formData,
          params: {
            id: this.$store.state.loginInfo.id,
          },
        };
        this.isLoading = true;
        myAxios(requestConfig)
          .then((res) => {
            // this.$router.go({
            //   path: this.$router.currentRoute.path,
            // });
            this.$store.commit("setUserPic", this.selectedFile.name);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // deleteImage() {
    //   this.picture = require('@/assets/images/user.png'),
    //   this.show = false
    //   this.$nextTick(function () {
    //     this.show = true
    //   })
    // },
    checkFile(file) {
      this.result = true;
      let SIZE_LIMIT = 5000000; // 5MB
      // キャンセルしたら処理中断
      if (!file) {
        this.result = false;
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.result = false;
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        this.result = false;
      }
      return this.result;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.image_picker {
  ul {
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    li {
      list-style: none;
      width: 31%;
      margin-right: 3.5%;
      margin-bottom: 3.5%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
.user_info {
  ul {
    display: flex;
    li {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.is-sp {
  .user_info {
    ul {
      display: block;
      li {
        justify-content: center;
      }
    }
  }
}
.file_button {
  display: none;
}
.mdi-account-supervisor-circle::before {
    font-size: 28px;
}
</style>